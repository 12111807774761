import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatTabGroup } from '@angular/material/tabs';
import { environment } from 'src/environments/environment';
import { sweetAlertService } from 'src/app/shared/sweetalert/sweetalert.service';
import { DomSanitizer } from '@angular/platform-browser';

import type { AuthStoreService } from '../../core/auth/auth.service';
import type { ExpenseCategoryService } from '../../core/backend/expense-category/expense-category.service';
import type { JobService } from '../../core/backend/job/job.service';
import type { JobStoreService } from '../../core/job/job.service';
import type { UserBackendModel } from 'src/app/core/backend/customer/model/UserBackend.model';

import type { EquipmentType } from 'src/app/core/backend/shared/model/equipment';
import type { ExpenseCategoryModel } from 'src/app/core/backend/expense-category/model/expense-category.model';

import type { JobModel } from 'src/app/core/backend/job/model/job.model';
import { BehaviorSubject, Observable } from 'rxjs';
import type { Router } from '@angular/router';
import type { ExpenseService } from 'src/app/core/backend/expenses/expense.service';
import type { ExpenseModel } from 'src/app/core/backend/expenses/model/expense.model';
import { BoolAsInt } from 'src/app/core/backend/shared/model/bool-as-int';
import { SpinnerService } from 'src/app/shared/spinner/spinner.service';

@Component({
  selector: 'app-receipt-capture-detail',
  templateUrl: './receipt-capture-detail.component.html',
  styleUrls: ['./receipt-capture-detail.component.scss'],
})
export class ReceiptCaptureDetailComponent implements OnInit, OnDestroy {
  @ViewChild(MatTabGroup) tabGroup: MatTabGroup;

  previewedFile: string = '';
  previewedFileName: string = '';
  images: File[] = [];
  isLoading: boolean = false;
  isSubmitting: boolean = false;
  filesUploaded: number = 0;
  filesUploading: number = 0;
  progressBar: HTMLProgressElement | null = null;
  dropArea: HTMLElement | null;
  selectedIndex: number = 0;
  canShowFuelFields: boolean = false;
  canShowMaintFields: boolean = false;
  viewingUserInfo: UserBackendModel;

  equipments: EquipmentType[] = [];
  favoriteEquipments: EquipmentType[] = [];
  favoriteJobs: JobModel[] = [];
  jobs$: Observable<JobModel[]>;
  jobs: JobModel[] = [];
  expenseCategories: ExpenseCategoryModel[] = [];
  filteredEquipments: EquipmentType[] = [];
  filteredFavoriteEquipments: EquipmentType[] = [];
  filteredFavoriteJobs: JobModel[] = [];
  filteredJobs: JobModel[] = [];

  // Form Fields
  amount = new FormControl(0);
  jobControl = new FormControl('');
  selectedDate = new FormControl(new Date());
  equipmentControl = new FormControl('');
  expenseControl = new FormControl('', Validators.required);
  mileage = new FormControl(null);
  notes = new FormControl('');

  // Add / Edit mode
  mode: 'add' | 'edit' = 'add';
  id: number = 0;

  favoriteLabel = 'Favorite';
  public loading$ = new BehaviorSubject<boolean>(false);

  constructor(
    private authStoreService: AuthStoreService,
    private jobService: JobService,
    private jobStoreService: JobStoreService,
    private expenseCategoryService: ExpenseCategoryService,
    private sweetAlertService: sweetAlertService,
    private route: Router,
    private expenseService: ExpenseService,
    private sanitizer: DomSanitizer,
    private spinnerService: SpinnerService
  ) {}

  ngOnInit(): void {
    this.initDragDropListener();

    this.progressBar = document.getElementById(
      'progress-bar'
    ) as HTMLProgressElement;

    const qp = this.route.parseUrl(this.route.url).queryParams;

    this.mode = qp.mode;
    this.id = qp.id;

    if (this.mode === 'edit') {
      this.getExpenseData(qp.id);
    }

    this.jobs$ = this.jobStoreService.getAllJobs();
    this.jobStoreService.requestJobs(this.getPayloadForJobs());

    this.jobs$.subscribe((fetchedJobs) => {
      this.populateJobDropdown(fetchedJobs);
    });

    this.populateExpenseCategoryDropdown();
    this.getAllEquipment();
    this.spinnerService.manageSpinnerState();
  }

  getToken(): string {
    const tokenFromStorage = sessionStorage.getItem('LOGIN_TOKEN_KEY') ?? '';
    return JSON.parse(tokenFromStorage);
  }

  async getExpenseData(expenseId: number) {
    const res = await this.expenseService.fetchExpenseById(expenseId);
    this.setForm(res);
  }

  async setForm(res: ExpenseModel) {
    this.amount.setValue(res.amount);
    this.tabGroup.selectedIndex = res.receipt_type - 1;

    this.jobControl.setValue(res.job_name);
    this.expenseControl.setValue(res.expense_category_name);
    this.equipmentControl.setValue(res.equipment_name);
    this.selectedDate.setValue(res.receipt_date);
    this.mileage.setValue(res.mileage);
    this.notes.setValue(res.notes);

    this.previewedFileName = res.s3_file_name;

    const mime = `image/${res.mime_type}`;

    const url = this.sanitizer.bypassSecurityTrustResourceUrl(
      `data:${mime};base64,${res.s3_file_base64}`
    );

    // @ts-ignore
    const byteCharacters = atob(res.s3_file_base64);
    const byteArrays = [];

    for (let i = 0; i < byteCharacters.length; i++) {
      byteArrays.push(byteCharacters.charCodeAt(i));
    }

    const byteArray = new Uint8Array(byteArrays);

    this.images = [new File([byteArray], res.s3_file_name)];

    // @ts-ignore
    this.previewedFile = url.changingThisBreaksApplicationSecurity as string;
  }

  initDragDropListener() {
    this.dropArea = document.getElementById('drop-area');

    // Listeners to ignore default browser behavior
    this.dropArea?.addEventListener(
      'dragenter',
      (e) => this.handleDragEvent(e),
      false
    );
    this.dropArea?.addEventListener(
      'dragleave',
      (e) => this.handleDragEvent(e),
      false
    );
    this.dropArea?.addEventListener(
      'dragover',
      (e) => this.handleDragEvent(e),
      false
    );
    this.dropArea?.addEventListener(
      'drop',
      (e) => this.handleDragEvent(e),
      false
    );

    // Listeners to handle highlighting drop area
    this.dropArea?.addEventListener(
      'dragenter',
      (e) => this.handleHighlight(e),
      false
    );
    this.dropArea?.addEventListener(
      'dragover',
      (e) => this.handleHighlight(e),
      false
    );

    // Listeners to handle un-highlighting drop area
    this.dropArea?.addEventListener(
      'dragleave',
      (e) => this.handleUnHighLight(e),
      false
    );
    this.dropArea?.addEventListener(
      'drop',
      (e) => this.handleUnHighLight(e),
      false
    );

    // Listener for when a file has been dropped onto the upload element.
    this.dropArea?.addEventListener('drop', (e) => this.handleDrop(e), false);

    this.expenseControl.valueChanges.subscribe((res) =>
      this.handleExpenseChange(res)
    );
  }

  getPayloadForJobs() {
    const reqObj = {
      customer_id: null,
      department_id: null,
      state: null,
      city: null,
      search: null,
      status: null,
    };

    return reqObj;
  }

  async populateJobDropdown(jobs: JobModel[]) {
    this.authStoreService.getCurrentUserInfo().subscribe(async (userData) => {
      this.viewingUserInfo = userData;

      this.favoriteJobs = jobs.filter((el) => el.is_job_favorite) ?? [];
      this.filteredFavoriteJobs = this.favoriteJobs;

      this.jobControl.valueChanges.subscribe((value) =>
        this._filter(value, 'jobs')
      );
      this.equipmentControl.valueChanges.subscribe((value) =>
        this._filter(value, 'equipments')
      );
    });

    this.jobs = jobs;
    this.filteredJobs = jobs;
  }

  _filter(value: string, key: 'equipments' | 'jobs') {
    if (!key) return;

    if (!value && key === 'jobs') {
      this.filteredFavoriteJobs = this.favoriteJobs;
      this.filteredJobs = this.jobs;
    } else if (key === 'jobs') {
      this.filteredFavoriteJobs =
        this.favoriteJobs.filter((el) =>
          el.name.toLowerCase().includes(value.toLowerCase())
        ) ?? [];
      this.filteredJobs =
        this.jobs.filter((el) =>
          el.name.toLowerCase().includes(value.toLowerCase())
        ) ?? [];
    } else if (!value && key === 'equipments') {
      this.filteredFavoriteEquipments = this.favoriteEquipments;
      this.filteredEquipments = this.equipments;
    } else if (key === 'equipments') {
      this.filteredFavoriteEquipments =
        this.favoriteEquipments.filter((el) =>
          el.name.toLowerCase().includes(value.toLowerCase())
        ) ?? [];
      this.filteredEquipments =
        this.equipments.filter((el) =>
          el.name.toLowerCase().includes(value.toLowerCase())
        ) ?? [];
    }
  }

  onSelect(e) {
    this.filteredFavoriteEquipments = this.favoriteEquipments;
    this.filteredEquipments = this.equipments;
    this.filteredFavoriteJobs = this.favoriteJobs;
    this.filteredJobs = this.jobs;
  }

  async populateExpenseCategoryDropdown() {
    const categories = await this.expenseCategoryService.getAllCategories();
    this.expenseCategories = categories;
  }

  async getAllEquipment() {
    const equipments = await this.jobService.getAllEquipments(null);
    this.equipments = equipments;

    this.favoriteEquipments =
      equipments.filter((el) => el.is_equipment_favorite) ?? [];

    this.filteredFavoriteEquipments = this.favoriteEquipments;

    this.filteredEquipments = equipments;
  }

  handleHighlight(e: DragEvent) {
    this.dropArea?.classList.add('highlight');
  }

  handleUnHighLight(e: DragEvent) {
    this.dropArea?.classList.remove('highlight');
  }

  handleDragEvent(e: DragEvent) {
    e.preventDefault();
    e.stopPropagation();
  }

  handleChange(e: Event) {
    const target = e.target as HTMLInputElement;
    const files = target.files;

    this.handleFiles(files);
  }

  handleFiles(files: FileList) {
    if (!files?.length) return;

    this.images = Array.from([...files]);
    this.images.forEach((f) => this.previewFile(f));
  }

  handleDrop(e: DragEvent) {
    const dt = e.dataTransfer;
    const files = dt?.files;

    this.handleFiles(files);
  }

  handleExpenseChange(newValue: string) {
    if (!newValue) return;

    const isFuel = newValue.toLowerCase() === 'fuel';
    const isMaint = newValue.toLowerCase() === 'maintenance';

    this.canShowFuelFields = isFuel;
    this.canShowMaintFields = isMaint;
  }

  /* For previewing files in UI */
  async previewFile(file: File) {
    if (!file) return;

    if (file.type === 'application/pdf') {
      const image = await this.expenseService.convertPdfToPng(file);

      return this.readImage(image, true);
    }
    return this.readImage(file);
  }

  readImage(file: File, unsafe = false) {
    const reader = new FileReader();

    reader.readAsDataURL(file);

    reader.onloadend = async () => {
      if (unsafe) {
        const url = this.sanitizer.bypassSecurityTrustResourceUrl(
          reader.result as string
        );

        this.previewedFile = url as string;
      } else {
        this.previewedFile = reader.result as string;
      }
      this.previewedFileName = file.name;
    };
  }

  handleImageClick() {
    window.open(this.previewedFile);
  }

  removeImage(event) {
    this.images = [];
    this.previewedFile = '';
  }

  onTabClick(e: Record<string, number>) {
    this.selectedIndex = e.index;
  }

  getLabel() {
    return this.selectedIndex ? 'Amt Req' : 'Amt';
  }

  getJobLabel(job: JobModel) {
    return `${job.job_number} ${job.name}`;
  }

  getEquipmentLabel(unit: EquipmentType) {
    return `${unit.full_equipment_name}`;
  }

  getFavoriteLabel() {
    const unit = this.favoriteEquipments.find(
      (el) => this.getEquipmentLabel(el) === this.equipmentControl.value
    );

    this.favoriteLabel = unit ? 'Unfavorite' : 'Favorite';

    return this.favoriteLabel;
  }

  setFavoriteIconColor() {
    const selectedEquipment = this.equipments.find(
      (el) => this.getEquipmentLabel(el) === this.equipmentControl.value
    );

    const favClass = 'fa fa-star ylw_yellow';
    const unFavClass = 'fa fa-star gry';

    return selectedEquipment?.is_equipment_favorite ? favClass : unFavClass;
  }

  async onFavoritePress(event) {
    event.preventDefault();

    const selectedEquipment = this.equipments.find(
      (el) => this.getEquipmentLabel(el) === this.equipmentControl.value
    );

    if (!selectedEquipment) return;

    const isFav = selectedEquipment.is_equipment_favorite;
    const employee_id = this.viewingUserInfo.id;

    const payload = {
      equipment_id: selectedEquipment.id,
      employee_id,
      is_equipment_favorite: 1 as BoolAsInt, // BE handles switching this if record exists.
    };

    await this.jobService.favoriteSingleEquipment(payload);
    await this.getAllEquipment();

    this.favoriteLabel = isFav ? 'Favorite' : 'Unfavorite';
  }

  onReset() {
    this.amount.reset(0);
    this.jobControl.reset('');
    this.equipmentControl.reset('');
    this.expenseControl.reset('');
    this.selectedDate.reset(new Date());
    this.mileage.reset(0);
    this.notes.reset('');

    this.removeImage(undefined);
  }

  async onSubmit() {
    try {
      this.isSubmitting = true;
      this.loading$.next(true);

      const slug = this.mode === 'add' ? this.mode : this.id;

      const url = `${environment.apiUrl}/expenses/${slug}`;

      const urlObj = new URL(url);

      const employee_id = this.viewingUserInfo.id.toString();

      const existsInFavoriteJobs = this.favoriteJobs.find(
        (el) => this.getJobLabel(el) === this.jobControl.value
      );
      const existsInJobs = this.jobs.find(
        (el) => this.getJobLabel(el) === this.jobControl.value
      );

      const selectedExpense = this.expenseCategories.find(
        (el) => el.name === this.expenseControl.value
      );

      const selectedEquipment = this.equipments.find(
        (el) => this.getEquipmentLabel(el) === this.equipmentControl.value
      );

      const selectedJob = existsInFavoriteJobs ?? existsInJobs;

      if (!this.images?.length) return;

      const job_id: string = `${selectedJob?.id}`;

      const token = this.getToken();

      const form = new FormData();
      form.append('employee_id', employee_id);
      form.append('input_files', this.images[0], this.images[0].name);
      form.append('job_id', job_id);
      form.append('amount', this.amount.value);
      form.append('receipt_date', this.selectedDate.value);
      form.append('receipt_type', this.selectedIndex == 0 ? '1' : '2');
      form.append('expense_category_id', `${selectedExpense?.id ?? ''}`);
      form.append('notes', this.notes.value);

      form.append('equipment_id', `${selectedEquipment?.id ?? ''}`);
      form.append('ce_equipment_id', `${selectedEquipment?.id ?? ''}`);

      if (this.mileage.value) {
        form.append('mileage', this.mileage.value);
      }

      const httpHeaders = {
        Authorization: `Bearer ${token}`,
      };

      const headers = new Headers(httpHeaders);
      const method = this.mode === 'add' ? 'POST' : 'PUT';

      const options: RequestInit = {
        method,
        headers,
        body: form,
        redirect: 'follow',
      };

      const didSubmit = await this.expenseService.postNewExpense(
        urlObj.toString(),
        options
      );

      if (didSubmit) {
        this.isSubmitting = false;
        this.onReset();
        this.sweetAlertService.saveEntry();
      }
    } catch (error) {
      console.error('SUBMIT ERROR:', error);
    } finally {
      this.isSubmitting = false;

      this.loading$.next(false);
    }
  }

  ngOnDestroy(): void {
    this.dropArea?.removeEventListener('dragenter', () => {});
    this.dropArea?.removeEventListener('dragleave', () => {});
    this.dropArea?.removeEventListener('dragover', () => {});
    this.dropArea?.removeEventListener('drop', () => {});
  }
}

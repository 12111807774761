<section>
  <!-- Loading Spinner for waiting for API response -->
  <div *ngIf="loading$ | async; let loading">
    <div id="loading-spinner" *ngIf="loading"><mat-spinner></mat-spinner></div>
  </div>

  <div class="container-fluid">
    <div class="row">
      <div class="filter-section">
        <div class="col-lg-8 col-sm-12 col-md-12 row inputs">
          <div class="col-lg-3 col-md-3 col-sm-12">
            <input
              onfocus="(this.type='date')"
              placeholder="Start Date"
              class="form-control"
              (input)="filterByDate('start_date', $event)"
              [(ngModel)]="start_date"
            />
          </div>

          <div class="col-lg-3 col-md-3 col-sm-12">
            <input
              onfocus="(this.type='date')"
              placeholder="End Date"
              class="form-control"
              (input)="filterByDate('end_date', $event)"
              [(ngModel)]="end_date"
            />
          </div>

          <div class="col-lg-3 col-md-3 col-sm-12">
            <input
              matInput
              aria-label="Employee autocomplete"
              type="text"
              placeholder="Search by employee name"
              class="form-control"
              [matAutocomplete]="autoUser"
              [formControl]="userControl"
              required
            />
            <mat-autocomplete
              #autoUser="matAutocomplete"
              (optionSelected)="onSelect('employees')"
            >
              <mat-option
                *ngFor="let user of filteredUsers"
                [value]="getUserLabel(user)"
              >
                {{ getUserLabel(user) }}
              </mat-option>
            </mat-autocomplete>
          </div>

          <div class="col-lg-3 col-md-3 col-sm-12">
            <input
              matInput
              aria-label="Job Autocomplete"
              type="text"
              placeholder="Search by Job #"
              class="form-control"
              [matAutocomplete]="autoJob"
              [formControl]="jobControl"
              required
            />
            <mat-autocomplete
              #autoJob="matAutocomplete"
              (optionSelected)="onSelect('jobs')"
            >
              <p class="autocomplete-label" *ngIf="filteredFavoriteJobs.length">
                MY FAVORITES
              </p>
              <mat-option
                *ngFor="let job of filteredFavoriteJobs"
                [value]="getJobLabel(job)"
              >
                {{ getJobLabel(job) }}
              </mat-option>
              <mat-divider [vertical]="false"></mat-divider>
              <mat-option
                *ngFor="let job of filteredJobs"
                [value]="getJobLabel(job)"
              >
                {{ getJobLabel(job) }}
              </mat-option>
            </mat-autocomplete>
          </div>
        </div>

        <div class="col-lg-4 col-sm-12 col-md-12 btns">
          <button
            class="btn btn-primary clear-filters col-sm-12 col-md-3"
            type="button"
            (click)="clearFilters()"
          >
            Clear filters
          </button>
          <button
            class="btn btn-primary clear-filters col-sm-12 col-md-3"
            type="button"
            (click)="navigateToAdd()"
          >
            Add Receipt
          </button>
          <button
            class="btn btn-primary clear-filters col-sm-12 col-md-3"
            type="button"
            (click)="downloadPdf()"
          >
            Download PDF
          </button>
          <button
            class="btn btn-primary clear-filters col-sm-12 col-md-3"
            type="button"
            (click)="downloadCsv()"
          >
            Download CSV
          </button>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="table-section">
        <table class="table">
          <thead>
            <tr>
              <th scope="col"></th>
              <th scope="col">Receipt Date</th>
              <th scope="col">Employee</th>
              <th scope="col">Type</th>
              <th scope="col">Amount</th>
              <th scope="col">Job #</th>
              <th scope="col">Job Name</th>
              <th scope="col">Expense</th>
              <th scope="col">Upload Date</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let data of filteredEntries">
              <td>
                <span>
                  <img
                    style="cursor: pointer"
                    (click)="navigateToEdit(data)"
                    height="16px"
                    src="../../assets/view.png"
                  />
                </span>
              </td>
              <td>{{ data?.receipt_date | date: "MM/dd/yyyy" }}</td>
              <td>{{ data?.employee_name }}</td>
              <td>{{ data?.receipt_type_name }}</td>
              <td>{{ formatCurrency(data?.amount) }}</td>
              <td>{{ data?.job_number }}</td>
              <td>{{ data?.job_name }}</td>
              <td>{{ data?.expense_category_name }}</td>
              <td>{{ data?.created_date | date: "MM/dd/yyyy" }}</td>
            </tr>
            <tr *ngIf="filteredEntries.length === 0" class="empty-section">
              <div>
                <p style="color: #930003">No records</p>
              </div>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</section>
<ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-scale-multiple"
>
  <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>
